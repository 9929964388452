var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.fetchingSpaces
    ? _c(
        "div",
        { attrs: { fluid: "", flat: "" } },
        [
          _vm.tablesFetching !== true && _vm.currentTables.length > 0
            ? [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _vm.currentTables.length > 0
                          ? _c("v-switch", {
                              staticClass: "my-0 pt-6 mx-2",
                              attrs: {
                                "hide-details": "",
                                label: "SQL Editor",
                                "e2e-snapshot-tables-sql-switch": "",
                              },
                              model: {
                                value: _vm.showSqlEditor,
                                callback: function ($$v) {
                                  _vm.showSqlEditor = $$v
                                },
                                expression: "showSqlEditor",
                              },
                            })
                          : _vm._e(),
                        _vm.showSqlEditor
                          ? _c(
                              "div",
                              { staticClass: "mt-3" },
                              [
                                _c(
                                  "v-sheet",
                                  { staticClass: "rounded overflow-hidden" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex align-center",
                                        staticStyle: {
                                          "background-color": "#43e0cf",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "caption ma-2" },
                                          [_vm._v("SQL Editor")]
                                        ),
                                        _c("v-spacer"),
                                        _c(
                                          "div",
                                          { staticClass: "ma-2" },
                                          [
                                            !_vm.fullEditor
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      small: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.fullEditor = true
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("open_in_full")]
                                                    ),
                                                    _vm._v(" Expand "),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      small: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.fullEditor = false
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [
                                                        _vm._v(
                                                          "close_fullscreen"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" Shrink "),
                                                  ],
                                                  1
                                                ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("monaco-editor", {
                                      staticClass: "monaco-editor",
                                      style: {
                                        height: _vm.fullEditor
                                          ? "calc(100vh - 350px)"
                                          : "88px",
                                      },
                                      attrs: {
                                        language: "sql",
                                        options: {
                                          minimap: { enabled: false },
                                          fontSize: 14,
                                          renderLineHighlight: false,
                                          automaticLayout: true,
                                          autoIndent: true,
                                          lineNumbersMinChars: 5,
                                          scrollbar: {
                                            vertical: "visible",
                                            alwaysConsumeMouseWheel: false,
                                          },
                                          scrollBeyondLastLine: false,
                                          smoothScrolling: true,
                                        },
                                      },
                                      model: {
                                        value: _vm.sqlQuery,
                                        callback: function ($$v) {
                                          _vm.sqlQuery = $$v
                                        },
                                        expression: "sqlQuery",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("query-submit", {
                                  attrs: {
                                    mode: _vm.queryModeOptions.PREVIEW,
                                    buttonName: "preview",
                                    tooltipHint:
                                      "Returns a preview sample of the query outcome.",
                                    iconShaped: false,
                                    consoleSql: _vm.sqlQuery,
                                  },
                                  on: { success: _vm.submitQuery },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "mt-5", attrs: { align: "center" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "7" } },
                      [
                        _c(
                          "v-tabs",
                          {
                            attrs: { height: "25", "show-arrows": "" },
                            model: {
                              value: _vm.tabView,
                              callback: function ($$v) {
                                _vm.tabView = $$v
                              },
                              expression: "tabView",
                            },
                          },
                          [
                            _c("v-tab", { attrs: { dark: "", height: "10" } }, [
                              _c("span", { staticClass: "caption" }, [
                                _vm._v(
                                  "tables (" +
                                    _vm._s(_vm.currentTables.length) +
                                    ")"
                                ),
                              ]),
                            ]),
                            _c(
                              "v-tab",
                              {
                                attrs: {
                                  dark: "",
                                  height: "10",
                                  disabled: !_vm.currentColumns.length,
                                },
                              },
                              [
                                _c("span", { staticClass: "caption" }, [
                                  _vm._v(
                                    "columns (" +
                                      _vm._s(_vm.currentColumns.length) +
                                      ")"
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "v-tab",
                              {
                                attrs: {
                                  dark: "",
                                  height: "10",
                                  disabled: !_vm.selectedTableForPreview,
                                },
                              },
                              [
                                _c("span", { staticClass: "caption" }, [
                                  _vm._v("table preview"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-tabs-items",
                  {
                    model: {
                      value: _vm.tabView,
                      callback: function ($$v) {
                        _vm.tabView = $$v
                      },
                      expression: "tabView",
                    },
                  },
                  [
                    _c(
                      "v-tab-item",
                      { staticClass: "mt-5" },
                      [
                        _c("SnapshotTableList", {
                          on: {
                            switchTab: function ($event) {
                              _vm.tabView = $event.tab
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      { staticClass: "mt-3" },
                      [_c("SnapshotTableColumns")],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      { staticClass: "mt-3" },
                      [
                        _c("SnapshotTablePreview", {
                          attrs: {
                            columnsData: _vm.previewData,
                            headersData: _vm.previewColumnHeaders,
                            fetchingData: _vm.previewFetching,
                            tableName: _vm.selectedTableForPreview,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm.tablesFetching === true
            ? _c("div", { staticClass: "mt-5" }, [
                _c(
                  "div",
                  [
                    _c("v-skeleton-loader", {
                      staticClass: "mx-auto",
                      attrs: { type: "text" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  _vm._l(3, function (n, i) {
                    return _c("v-skeleton-loader", {
                      key: i,
                      staticClass: "mx-auto",
                      attrs: { type: "list-item-three-line" },
                    })
                  }),
                  1
                ),
              ])
            : _vm.tablesFetching === false && !_vm.currentTables.length
            ? _c(
                "v-banner",
                {
                  staticClass: "mt-5",
                  attrs: { "single-line": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "actions",
                        fn: function () {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", small: "", outlined: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$store.dispatch(
                                      "snapshotStore/fetchCurrentTables",
                                      _vm.$route.params.snid
                                    )
                                  },
                                },
                              },
                              [_vm._v("Refresh")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1021162188
                  ),
                },
                [
                  _c(
                    "v-avatar",
                    {
                      attrs: { slot: "icon", color: "white", size: "60" },
                      slot: "icon",
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { "x-large": "", icon: "info", color: "info" },
                        },
                        [_vm._v("info")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("No tables found"),
                    ]),
                    _vm.isDevelopment
                      ? _c("span", [
                          _vm._v(
                            " No tables were found. This might be because there are no tables in this space or tables have not been activated. "
                          ),
                        ])
                      : _c("span", [
                          _vm._v("No tables were found in this snapshot."),
                        ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }